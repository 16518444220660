(function(){

require('./settings/iframe_api.js');

window.addEventListener('load',()=>{
    //check if there are any frontend sections
    let sections = document.querySelectorAll('.jims-section.frontend');
    sections.forEach( section=>{
        //check section's background type
        let bg_type = section.getAttribute('data-bgType');

        //case 1. Youtube Background
        if( bg_type==="youtube" ){
            loadYTVideo( section );
        }

        //case 2. Self Hosted Video Background
        else if( bg_type==="video" ){
            loadVideo( section );
        }
    });
});

function loadYTVideo( section ){
    //get container
    if( typeof YT === 'undefined' || YT === null ){
        setTimeout( loadYTVideo, 1000)
        return;
    }

    let container = section.querySelector('.yt-iframe');
    let youtubeId = container.getAttribute('data-ytID');
    let videoMuted = container.getAttribute('data-muted')==="true" ? true : false;
    if( container && YT && youtubeId!=="" ){
        let pl = new YT.Player(container, {
        height: '390',
        width: '640',
        videoId: youtubeId,
        playerVars: {
            'playsinline': 1,
            'loop': 1,
            'controls': 0,
            'autoplay': 1,
            'modestbranding': 1,
            'enablejsapi': 1,
            'disablekb': 1,
            'iv_load_policy': 3
        },
        events: {
            'onReady': onPlayerReady,
        }
        });

        //The API will call this function when the video player is ready.
        function onPlayerReady(event) {
        if( videoMuted )
            event.target.mute();
        }
    }
}



function loadVideo( section ){
    let video = section.querySelector(".background-media.video");
    if( video )
        video.load();
}

})();